<template>
  <div>
    <div class="titles">{{ __("Report Template") }}</div>
    <div class="details">{{ reportInstance.report.name }}</div>

    <div class="titles">{{ __("Job ID") }}</div>
    <div class="details">
      {{ reportInstance.job_id ? reportInstance.job_id : "unavailable" }}
    </div>

    <div v-if="!isReportInstanceIssuedBySystem">
      <div class="titles">{{ __("Requested By") }}</div>
      <div class="details">{{ reportInstance.requested_by.name }}</div>
    </div>
    <div v-else>
      <div class="titles">{{ __("Initiated By") }}</div>
      <div class="details">{{ reportInstance.initiator }}</div>
    </div>

    <div class="titles">{{ __("Requested At") }}</div>
    <div class="details">
      {{ formattedDateTime(reportInstance.created_at) }}
    </div>

    <div class="titles">{{ __("Status") }}</div>
    <div class="details" style="display: flex;align-items: center">
      <span style="text-transform: capitalize">
        {{ reportInstance.status }}</span
      >
      <div
        :style="getStatusStyles(reportInstance.status)"
        :class="getStatusClass(reportInstance.status)"
      ></div>
    </div>

    <template v-if="reportInstance.start_time">
      <div class="titles">{{ __("Processing started at") }}</div>
      <div class="details">
        {{ formattedDateTime(reportInstance.start_time) }}
      </div>
    </template>

    <template v-if="reportInstance.end_time">
      <div class="titles">{{ __("Processing finished at") }}</div>
      <div class="details">
        {{ formattedDateTime(reportInstance.end_time) }}
      </div>
    </template>
    <el-dropdown
      v-if="isReportInstanceInEndStatus"
      @command="handleAction"
      class="reportInstanceActionDropDown"
    >
      <el-button type="primary">
        {{ __("Actions") }}<i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>

      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          v-for="(command, index) in commands"
          :key="index"
          :command="command.command"
          :disabled="command.disabled()"
          v-show="command.show()"
        >
          <div
            style="display: flex; justify-content: flex-start; align-items: center"
          >
            <img
              v-if="command.icon"
              :src="getIconSVG(command.icon)"
              alt
              height="18px"
              width="18px"
              style="margin-right: 10px"
            />
            <span>{{ command.label() }}</span>
          </div>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <el-dialog
      v-if="showChartDialog"
      :visible.sync="showChartDialog"
      :close-on-click-modal="false"
      :show-close="true"
      append-to-body
      destroy-on-close
      lock-scroll
      @close="handleClose"
      width="90%"
      class="chart-dialog"
    >
      <report-instance-chart
        :report-instance="reportInstance"
        @error="handleClose"
      ></report-instance-chart>
    </el-dialog>
  </div>
</template>

<script>
import ReportInstanceChart from "@/views/analyse/reports/components/reportInstanceChart";
import FileDownloader from "@/mixins/FileDownloader";
import {
  getChartImageForReportInstance,
  getChartDataForReportInstance,
  // getRawChartDataForReportInstance,
  removeReportInstance,
  getChartTableDataForReportInstance
} from "@/api/reports";
import { mapGetters } from "vuex";
import { EventBus } from "@/EventBus";
import {
  STATUS_COMPLETED,
  STATUS_FAILED,
  STATUS_PROCESSING,
  STATUS_QUEUED
} from "@/views/analyse/reports/reportConstants";
import BackButtonHandler from "@/components/BackButtonHandler";

export default {
  name: "ReportInstanceInfo",
  mixins: [FileDownloader, BackButtonHandler],
  components: { ReportInstanceChart },
  props: {
    reportInstance: {
      type: Object,
      required: true
    },
    formAction: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      commands: [
        {
          command: "edit",
          show: () => !this.isReportInstanceIssuedBySystem,
          disabled: () => {
            return this.isReportInstanceIssuedBySystem;
          },
          icon: "campaign_actions/icon_pencil.svg",
          label: () => {
            return __("Edit Report");
          }
        },
        {
          command: "view",
          show: () => this.reportInstanceStatusIs(STATUS_COMPLETED),
          disabled: () => {
            return !this.reportInstanceStatusIs(STATUS_COMPLETED);
          },
          icon: "icon-preview.svg",
          label: () => {
            return __("View Report");
          }
        },
        {
          command: "download-chart",
          show: () => this.reportInstanceStatusIs(STATUS_COMPLETED),
          disabled: () => {
            return !this.reportInstanceStatusIs(STATUS_COMPLETED);
          },
          icon: "icon-download.svg",
          label: () => {
            return __("Download Report");
          }
        },
        {
          command: "download-data",
          show: () => this.reportInstanceStatusIs(STATUS_COMPLETED),
          disabled: () => {
            return !this.reportInstanceStatusIs(STATUS_COMPLETED);
          },
          icon: "icon-download.svg",
          label: () => {
            return __("Download CSV");
          }
        },
        {
          command: "download-table-data",
          show: () => this.reportInstanceStatusIs(STATUS_COMPLETED),
          disabled: () => {
            return !this.reportInstanceStatusIs(STATUS_COMPLETED);
          },
          icon: "icon-download.svg",
          label: () => {
            return __("Download Table Data");
          }
        },
        // commenting this out until we have a solid solution
        // {
        //   command: "download-raw",
        //   show: () => this.reportInstanceStatusIs(STATUS_COMPLETED),
        //   disabled: () => {
        //     return !this.reportInstanceStatusIs(STATUS_COMPLETED);
        //   },
        //   icon: "icon-download.svg",
        //   label: () => {
        //     return this.isDataFileZip
        //       ? __("Download Raw Zip")
        //       : __("Download Raw CSV");
        //   }
        // },
        {
          command: "retry",
          show: () =>
            this.reportInstanceStatusIs(STATUS_FAILED) &&
            (!this.isReportInstanceIssuedBySystem ||
              (this.isReportInstanceIssuedBySystem && this.isSystemUser)),
          icon: "campaign_actions/icon_reset.svg",
          disabled: () => {
            return !this.reportInstanceStatusIs(STATUS_FAILED);
          },
          label: () => {
            return __("Retry");
          }
        },
        {
          command: "delete",
          show: () => {
            return (
              (this.reportInstanceStatusIs(STATUS_COMPLETED) ||
                this.reportInstanceStatusIs(STATUS_FAILED)) &&
              ((this.$auth.user().email ===
                this.reportInstance.requested_by.email &&
                !this.isReportInstanceIssuedBySystem) ||
                (this.isReportInstanceIssuedBySystem && this.isSystemUser))
            );
          },
          icon: "campaign_actions/icon_trash_new.svg",
          disabled: () => {
            return (
              (!this.reportInstanceStatusIs(STATUS_COMPLETED) &&
                !this.reportInstanceStatusIs(STATUS_FAILED)) ||
              this.$auth.user().email !==
                this.reportInstance.requested_by.email ||
              (this.isReportInstanceIssuedBySystem && !this.isSystemUser)
            );
          },
          label: () => {
            return __("Delete");
          }
        }
      ],
      showChartDialog: false
    };
  },
  computed: {
    ...mapGetters("app", {
      formattedDateTime: "formattedDateTime"
    }),

    isSystemUser() {
      return this.$auth.user().roles.includes("system");
    },

    // isDataFileZip() {
    //   let rawFile = this.reportInstance.raw_file;
    //   if (rawFile && !_.isObject(rawFile)) {
    //     rawFile = JSON.parse(rawFile);
    //   }
    //   return "zip" === _.get(rawFile, "file_type", "zip");
    // },

    isReportInstanceInEndStatus() {
      return (
        this.reportInstanceStatusIs(STATUS_COMPLETED) ||
        this.reportInstanceStatusIs(STATUS_FAILED)
      );
    },
    isReportInstanceIssuedBySystem() {
      return this.reportInstance.initiator === "system";
    }
  },
  methods: {
    getStatusClass(status) {
      return status === STATUS_PROCESSING ? "processing" : "";
    },

    getStatusStyles(status) {
      let statusStyles = {
        width: "12px",
        height: "12px",
        "border-radius": "8px",
        "margin-left": "5px"
      };

      const statusColorMap = {
        [STATUS_PROCESSING]: "#0583D6",
        [STATUS_COMPLETED]: "#1AD1A1",
        [STATUS_FAILED]: "#F84856",
        [STATUS_QUEUED]: "#F0C046"
      };

      statusStyles["background-color"] =
        statusColorMap[status] || "var(--theme-color)";
      return statusStyles;
    },

    handleClose() {
      this.showChartDialog = false;
      this.$emit("preview-closed");
    },

    backButtonHandler() {
      if (this.showChartDialog) {
        this.showChartDialog = false;
        this.$emit("preview-closed");
        return false;
      }
      return true;
    },

    getIconSVG(name) {
      return require("@/assets/icons/" + name);
    },
    reportInstanceStatusIs(status) {
      return this.reportInstance.status === status;
    },
    handleAction: function(action) {
      if (action === "view" && this.reportInstanceStatusIs(STATUS_COMPLETED)) {
        this.showChartDialog = true;
      } else if (
        action === "download-chart" &&
        this.reportInstanceStatusIs(STATUS_COMPLETED)
      ) {
        getChartImageForReportInstance(this.reportInstance.report_instance_id)
          .then(response => {
            this.downloadFile(
              response.data,
              `${this.reportInstance.report.name}_${this.reportInstance.report_instance_id}.png`
            );
          })
          .catch(err => {
            this.$message({
              message: err.message || __("unable to download image"),
              type: "error"
            });
          });
      } else if (
        action === "download-data" &&
        this.reportInstanceStatusIs(STATUS_COMPLETED)
      ) {
        getChartDataForReportInstance(this.reportInstance.report_instance_id)
          .then(response => {
            this.downloadFile(
              response.data,
              `${this.reportInstance.report.name}_${this.reportInstance.report_instance_id}.csv`
            );
          })
          .catch(err => {
            this.$message({
              message: err.message || __("unable to download data"),
              type: "error"
            });
          });
      } else if (
        action === "download-table-data" &&
        this.reportInstanceStatusIs(STATUS_COMPLETED)
      ) {
        getChartTableDataForReportInstance(
          this.reportInstance.report_instance_id
        )
          .then(response => {
            this.downloadFile(
              response.data,
              `${this.reportInstance.report.name}_${this.reportInstance.report_instance_id}.csv`
            );
          })
          .catch(err => {
            this.$message({
              message: err.message || __("unable to download data"),
              type: "error"
            });
          });
      }
      // else if (
      //   action === "download-raw" &&
      //   this.reportInstanceStatusIs(STATUS_COMPLETED)
      // ) {
      //   getRawChartDataForReportInstance(this.reportInstance.report_instance_id)
      //     .then(response => {
      //       this.downloadFile(
      //         response.data,
      //         `${this.reportInstance.report.name}_${
      //           this.reportInstance.report_instance_id
      //         }.${this.isDataFileZip ? "zip" : "csv"}`
      //       );
      //     })
      //     .catch(err => {
      //       this.$message({
      //         message: err.message || __("unable to download data"),
      //         type: "error"
      //       });
      //     });
      // }
      else if (action === "delete" && this.isReportInstanceInEndStatus) {
        removeReportInstance(this.reportInstance.report_instance_id)
          .then(() => {
            EventBus.$emit("report-instances-list-changed");
            this.$emit("delete");
          })
          .catch(err => {
            this.$message({
              message: err.message || __("unable to remove report instance"),
              type: "error"
            });
          });
      } else if (action === "edit") {
        this.$emit("edit");
      } else if (action === "retry") {
        this.$emit("retry", this.reportInstance);
      }
    }
  },
  watch: {
    formAction: {
      immediate: true,
      handler(val) {
        if (val === "view" && this.reportInstanceStatusIs(STATUS_COMPLETED)) {
          this.showChartDialog = true;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.reportInstanceActionDropDown {
  margin-top: 15px;
}

@keyframes fade-in-fade-out {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.processing {
  animation: fade-in-fade-out 1s infinite;
}
</style>

<style lang="scss">
.chart-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  .el-dialog {
    margin-block: auto !important;
  }
}
</style>
