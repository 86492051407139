<script>
import { mapGetters } from "vuex";
import { presetDates } from "@/utils/time";

export default {
  name: "TimezoneMixin",
  computed: {
    ...mapGetters("app", {
      displayDateFormat: "displayDateFormat",
      timezone: "timezone",
      timezoneSource: "timezoneSource"
    }),

    pickerOptions() {
      let self = this;
      let shortCuts = [
        {
          text: __("Today"),
          onClick(picker) {
            picker.$emit("pick", [
              presetDates(self.timezone).today,
              presetDates(self.timezone).today
            ]);
          }
        },
        {
          text: __("Yesterday"),
          onClick(picker) {
            picker.$emit("pick", [
              presetDates(self.timezone).yesterday,
              presetDates(self.timezone).yesterday
            ]);
          }
        },
        {
          text: __("Last 7 days"),
          onClick(picker) {
            picker.$emit("pick", [
              presetDates(self.timezone).sevenDaysAgo,
              presetDates(self.timezone).yesterday
            ]);
          }
        },
        {
          text: __("This week"),
          onClick(picker) {
            picker.$emit("pick", [
              presetDates(self.timezone).startOfThisWeek,
              presetDates(self.timezone).endOfThisWeek
            ]);
          }
        },
        {
          text: __("Last week"),
          onClick(picker) {
            picker.$emit("pick", [
              presetDates(self.timezone).startOfLastWeek,
              presetDates(self.timezone).endOfLastWeek
            ]);
          }
        },
        {
          text: __("This month"),
          onClick(picker) {
            picker.$emit("pick", [
              presetDates(self.timezone).startOfThisMonth,
              presetDates(self.timezone).endOfThisMonth
            ]);
          }
        },
        {
          text: __("Last month"),
          onClick(picker) {
            picker.$emit("pick", [
              presetDates(self.timezone).startOfLastMonth,
              presetDates(self.timezone).endOfLastMonth
            ]);
          }
        },
        {
          text: __("Last 3 months"),
          onClick(picker) {
            picker.$emit("pick", [
              presetDates(self.timezone).startOfLastThreeMonth,
              presetDates(self.timezone).endOfLastMonth
            ]);
          }
        }
      ];

      return {
        shortcuts: shortCuts
      };
    }
  }
};
</script>
