<template>
  <div style="width: 100%;" @click="handleClearSelection">
    <page-header
      class="absolute"
      style="padding: 20px 30px; width: calc(100% - 335px)"
      :title="__('Reports')"
    />

    <el-button
      v-if="isReport"
      class="createBtn"
      @click.stop="createReportTemplate"
      v-show="this.canWrite()"
      >{{ __("Create Report Template") }}</el-button
    >
    <el-button
      v-else-if="!isReport"
      class="createBtn"
      @click.stop="createReport"
      v-show="this.canWrite()"
      >{{ __("Create Report") }}</el-button
    >
    <div style="padding: 0 36px; margin-top: 100px;">
      <el-tabs v-model="activeTab">
        <el-tab-pane name="report-templates">
          <div style="display: flex;align-items: center" slot="label">
            <img
              :src="getIcon('reports/icon-report-template.svg')"
              alt="icon"
              style="width: 24px; height: 24px;"
              onload="SVGInject(this)"
            /><span style="font-size: 1rem; margin-left: 5px">{{
              __("Report Templates")
            }}</span>
          </div>
          <el-form class="form-container">
            <PaginationToolbar
              v-if="isReport"
              :sort-by-options="sortByOptions"
              :content-api="contentAPI"
              list-changed-event-name="report-list-changed"
            >
            </PaginationToolbar>
          </el-form>
          <el-scrollbar :native="false">
            <div class="contentCardsContainer">
              <el-table
                v-loading="isReportDataStoresLoading"
                row-key="report_id"
                ref="reportsTable"
                class="list-table"
                :data="getReportsWithDataStores"
                highlight-current-row
                @row-click="handleSingleReportClick"
                @row-dblclick="handleSingleReportDoubleClick"
                v-show="this.canRead()"
              >
                <el-table-column :label="__('Name')" prop="report_name" />
                <el-table-column
                  :label="__('Description')"
                  prop="report_description"
                  min-width="150"
                >
                  <template slot-scope="scope">
                    <el-tooltip
                      :id="generateRef(scope)"
                      placement="top-end"
                      class="report-desc"
                      :disabled="!isActiveEllipsis(scope)"
                      ><div class="report-desc-tooltip" slot="content">
                        {{ showReportDescriptionToolTip(scope) }}
                      </div>
                      <div>
                        {{ showReportDescriptionToolTip(scope) }}
                      </div>
                    </el-tooltip>
                  </template>
                </el-table-column>

                <el-table-column :label="__('Graph Type')" prop="chart_style" />
                <el-table-column
                  :label="__('Datastore')"
                  prop="datastore.display_name"
                  min-width="100"
                >
                  <template slot-scope="scope">
                    <div>
                      {{ showDataStoreName(scope) }}
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-scrollbar>
        </el-tab-pane>

        <el-tab-pane name="report-instances">
          <div style="display: flex;align-items: center" slot="label">
            <img
              :src="getIcon('reports/icon-report.svg')"
              alt="icon"
              style="width: 24px; height: 24px;"
              onload="SVGInject(this)"
            /><span style="font-size: 1rem; margin-left: 5px;">{{
              __("Reports")
            }}</span>
          </div>
          <el-form class="form-container">
            <PaginationToolbar
              v-if="!isReport"
              :sort-by-options="sortByOptions"
              :content-api="reportInstancesForAccountAPI"
              list-changed-event-name="report-instances-list-changed"
              :search-options="reportInstanceSearchOptions"
              show-slot
              :search-prefill="getSearchPrefill"
            >
              <div
                style="display: flex; align-items:center; padding: 0 10px"
                class="buttonGroup"
                slot="buttonGroup"
              >
                <el-select
                  filterable
                  v-model="statusFilter"
                  class="statusFilterDropdown"
                  style="margin-left:10px;width: 100%"
                  multiple
                  default-first-option
                  collapse-tags
                  clearable
                  :placeholder="__('Filter By Status')"
                >
                  <el-option
                    v-for="status in statuses"
                    :key="status"
                    :label="status"
                    :value="status"
                  >
                  </el-option>
                </el-select>
                <el-checkbox
                  ref="byMeCheckBox"
                  v-model="byMeFlag"
                  style="margin-left: 5px; padding-left: 3px"
                >
                  {{ __("Show only my reports") }}
                </el-checkbox>
              </div>
            </PaginationToolbar>
          </el-form>
          <el-scrollbar :native="false">
            <div class="contentCardsContainer">
              <report-instance-table
                v-if="!isReport"
                ref="report-instances-table"
                :report-instances="reportInstances"
                @select="handleReportSingleClick"
                @edit="handleReportDoubleClick"
                @show-chart="handleView"
                :form-action="formAction"
              />
            </div>
          </el-scrollbar>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import PaginationToolbar from "@/components/PaginationToolbar";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import PageHeader from "@/components/PageHeader";
import ReportInstanceTable from "@/views/analyse/reports/components/ReportInstancesTable";
import { EventBus } from "@/EventBus";
import _ from "lodash";
import {
  STATUS_COMPLETED,
  STATUS_FAILED,
  STATUS_PROCESSING,
  STATUS_QUEUED
} from "@/views/analyse/reports/reportConstants";
import { SYSTEM_LOG_DS } from "@/constants/datastore";

export default {
  name: "ReportsList",
  mixins: [BaseContent],
  components: {
    ReportInstanceTable,
    PaginationToolbar,
    PageHeader
  },
  data() {
    return {
      sortByColumns: [],
      sortByColumnsForReports: ["report_name", "report_id"],
      sortByColumnsForReportInstances: [
        "report_instance_name",
        "report_instance_id"
      ],
      validateWrite: false,
      activeTab: "report-templates",
      reportInstanceSearchOptions: JSON.stringify({
        "by-me": false,
        "status-in": [],
        report_id: null
      }),
      statuses: [
        STATUS_COMPLETED,
        STATUS_QUEUED,
        STATUS_FAILED,
        STATUS_PROCESSING
      ],
      searchQuery: ""
    };
  },

  computed: {
    ...mapState("reports", {
      reports: state => state.reports,
      reportInstances: state => state.reportInstances,
      reportTemplatesLoading: state => state.loading,
      receivedUpdateEvent: state => state.receivedUpdateEvent
    }),

    ...mapGetters("dataStores", {
      dataStores: "getDataStores",
      dataStoresLoading: "getDataStoresLoading"
    }),

    isReportDataStoresLoading() {
      return this.reportTemplatesLoading || this.dataStoresLoading;
    },

    showReportDescriptionToolTip() {
      return scope =>
        scope.row.report_description === ""
          ? "-"
          : scope.row.report_description;
    },

    showDataStoreName() {
      return scope =>
        scope.row.datastore
          ? scope.row.datastore.is_log === 1 &&
            scope.row.datastore.display_name.includes(SYSTEM_LOG_DS)
            ? "=== SYSTEM LOG ==="
            : scope.row.datastore.display_name
          : "";
    },

    getSearchPrefill() {
      return this.searchQuery;
    },

    isReport() {
      return this.activeTab !== "report-instances";
    },

    isActiveEllipsis() {
      return scope => {
        let ref = this.generateRef(scope);
        let el = document.getElementById(ref);
        if (el) {
          return el.offsetWidth < el.scrollWidth;
        }
        return false;
      };
    },

    getReportsWithDataStores() {
      const datastores = this.dataStores;
      const reports = this.reports;
      const reportsWithDatastores = reports.map(item => {
        const mappedDataStore = _.find(datastores, {
          data_store_id: item.source_id
        });
        item.datastore = mappedDataStore;
        return item;
      });
      return reportsWithDatastores;
    },

    statusFilter: {
      get() {
        let searchOptions = JSON.parse(this.reportInstanceSearchOptions);
        return searchOptions["status-in"];
      },
      set(values) {
        let searchOptions = JSON.parse(this.reportInstanceSearchOptions);
        this.reportInstanceSearchOptions = JSON.stringify({
          ...searchOptions,
          "status-in": values
        });
      }
    },

    reportFilter: {
      get() {
        let searchOptions = JSON.parse(this.reportInstanceSearchOptions);
        return searchOptions["report_id"];
      },
      set(value) {
        let searchOptions = JSON.parse(this.reportInstanceSearchOptions);
        this.reportInstanceSearchOptions = JSON.stringify({
          ...searchOptions,
          report_id: value
        });
      }
    },

    byMeFlag: {
      get() {
        let searchOptions = JSON.parse(this.reportInstanceSearchOptions);
        return _.get(searchOptions, "by-me", false);
      },
      set(value) {
        let searchOptions = JSON.parse(this.reportInstanceSearchOptions);
        this.reportInstanceSearchOptions = JSON.stringify({
          ...searchOptions,
          "by-me": value
        });
      }
    }
  },

  created() {
    EventBus.$on("open-report-template-create", () => {
      this.activeTab = "report-templates";
      this.createReportTemplate();
    });

    this.activeTab = this.$route.params["active-tab"] || "report-templates";
    this.searchQuery = this.$route.params.job || "";
  },

  beforeDestroy() {
    EventBus.$off("open-report-template-create");
  },

  methods: {
    ...mapActions("reports", {
      getReports: "getReports",
      contentAPI: "getReports",
      deleteContentMethod: "deleteReport",
      undoDeleteContent: "undoDeleteReport",
      reportInstancesForAccountAPI: "getReportInstances",
      updateReportInstanceUpdateEventStatus:
        "updateReportInstanceUpdateEventStatus"
    }),

    getIcon(icon) {
      if (icon) {
        return require("@/assets/icons/" + icon);
      }
    },

    createReportTemplate() {
      this.$emit("open-create-modal", "report-template");
      EventBus.$emit("open-create-modal");
    },

    generateRef(scope) {
      return "report-" + scope.row.report_id;
    },

    createReport() {
      this.$emit("open-create-modal", "report");
      EventBus.$emit("open-create-modal");
    },

    handleSingleReportClick(row, column, event) {
      event.stopPropagation();
      let index = _.findIndex(
        this.getReportsWithDataStores,
        report => report.report_id === row.report_id
      );
      index = index > -1 ? index : null;
      this.handleSelect(index, row);
    },

    handleReportSingleClick(row) {
      // find index of row
      let index = _.findIndex(
        this.reportInstances,
        reportInstance =>
          reportInstance.report_instance_id === row.report_instance_id
      );
      index = index > -1 ? index : null;
      this.handleSelect(index, row);
    },

    handleSingleReportDoubleClick(row, column, event) {
      event.stopPropagation();
      this.handleEdit(null, row);
    },

    handleReportDoubleClick(index, row) {
      this.handleEdit(index, row);
    },

    emitListChangedEvent() {
      if (this.isReport) {
        EventBus.$emit("report-list-changed");
      } else {
        EventBus.$emit("report-instances-list-changed");
      }
    }
  },
  watch: {
    activeTab: {
      immediate: true,
      handler() {
        if (this.isReport) {
          this.searchQuery = "";
          this.sortByColumns = this.sortByColumnsForReports;
          this.$emit("changeContent", {
            contentType: "report template",
            primaryKey: "report_id",
            contents: this.reports
          });
        } else {
          this.getReports();
          this.sortByColumns = this.sortByColumnsForReportInstances;
          this.$emit("changeContent", {
            contentType: "report",
            primaryKey: "report_instance_id",
            contents: this.reportInstances
          });
        }
      }
    },

    reportInstances: {
      deep: true,
      handler() {
        if (!this.isReport) {
          this.$emit("changeContent", {
            contentType: "report",
            primaryKey: "report_instance_id",
            contents: this.reportInstances
          });
        }
      }
    },
    receivedUpdateEvent: {
      handler(val) {
        if (
          val &&
          !_.isEmpty(this.statusFilter) &&
          this.statusFilter.length < this.statuses.length
        ) {
          EventBus.$emit("report-instances-list-changed");
        }
        this.updateReportInstanceUpdateEventStatus(false);
      }
    },
    selectedItemIndex: {
      immediate: true,
      handler: function(val) {
        if (val !== -1 && val !== null && val !== undefined) {
          this.$refs.reportsTable &&
            this.$refs.reportsTable.setCurrentRow(
              this.getReportsWithDataStores[val]
            );
        } else if (val === -1) {
          this.$refs.reportsTable && this.$refs.reportsTable.setCurrentRow();
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
$card-image-filter: invert(65%) sepia(43%) saturate(5014%) hue-rotate(170deg)
  brightness(104%) contrast(89%);
@import "~@/styles/content-buttons.scss";
@import "~@/styles/content-list.scss";
@import "~@/styles/button.scss";
@import "~@/styles/pagination-toolbar.scss";
@import "~@/styles/tags.scss";
.statusFilterDropdown {
  ::v-deep input {
    height: 44px !important;
  }
}

.el-tabs {
  ::v-deep .el-tabs__header {
    margin-bottom: 25px;
  }

  ::v-deep .el-tabs__item:hover,
  ::v-deep .el-tabs__item.is-active {
    svg {
      fill: var(--theme-color);

      path {
        fill: var(--theme-color);
      }
    }
  }
}

.report-desc {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selected {
  color: $content-theme-color;
  border-color: $content-theme-color;
  i {
    background-color: $content-theme-color;
  }
}
</style>

<style lang="scss">
$content-theme-color: #4db3f6 !default;
$content-theme-hover-color: dodgerblue !default;
$card-image-filter: invert(65%) sepia(43%) saturate(5014%) hue-rotate(170deg)
  brightness(104%) contrast(89%);
@import "~@/styles/content-buttons.scss";
@import "~@/styles/content-card.scss";
@import "~@/styles/pagination-toolbar.scss";
.report-desc-tooltip {
  width: 300px;
}
</style>
