<script>
import _ from "lodash";
import { mapActions } from "vuex";
import { Conditions } from "vue-renderless-expression-builder";
const { defaultFieldTypes } = Conditions.Defaults;
const defaultOrderBy = { column: "_timestamp", sortBy: "desc" };

export default {
  name: "ReportsUtil",
  computed: {
    columnsForReportQueryBuilder() {
      return datastoreStructure =>
        _.filter(
          datastoreStructure,
          item => !item.secure && item.name !== "_timestamp"
        );
    }
  },
  methods: {
    ...mapActions("datastorecollection", {
      setRequestData: "setRequestData"
    }),

    buildRequestData(dataStoreId, rootCondition, taskId = 0, timeFrame = "") {
      let requestData = {};
      requestData["data_store_id"] = dataStoreId;
      requestData["where"] = rootCondition;

      let newChildren = [];

      if (taskId > 0) {
        newChildren.push({
          fieldName: "_task_id",
          fieldTypeName: defaultFieldTypes.NUMBER,
          operatorName: "=",
          value: taskId
        });
      }

      if (timeFrame) {
        newChildren.push({
          fieldName: "_timestamp",
          fieldTypeName: "timestamp",
          operatorName: "between",
          value: timeFrame
        });
      }

      if (!_.isEmpty(newChildren)) {
        let newRule = {
          connectionType: "and",
          maxDepth: 0,
          children: newChildren
        };
        newRule["children"].push(requestData["where"]);
        requestData["where"] = newRule;
      }

      requestData["order_by"] = _.cloneDeep(defaultOrderBy);
      requestData["source"] = "REPORT";

      // todo:uncomment this line if the old confidential data is decided to be masked.
      // requestData["log_should_be_masked"] = this.task.is_system_log_hidden;
      this.setRequestData(requestData);
    }
  }
};
</script>
