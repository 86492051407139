<template>
  <el-input
    class="expression-builder-input"
    disabled
    :value="JSON.stringify(queryCondition)"
  >
    <div class="action-buttons" slot="suffix">
      <i
        style="cursor:pointer"
        class="el-icon-edit el-input__icon"
        @click="$emit('edit-input')"
      >
      </i>
    </div>
  </el-input>
</template>

<script>
export default {
  props: {
    queryCondition: {
      default: ""
    }
  }
};
</script>

<style scoped lang="scss">
.expression-builder-input {
  & ::v-deep .el-input__inner {
    cursor: default !important;
  }
}
</style>
