<template>
  <el-table
    ref="reportInstancesTable"
    class="list-table"
    :data="reportInstances"
    v-loading="reportsLoading"
    highlight-current-row
    :current-row-key="currentRowKey"
    row-key="report_instance_id"
    @row-click="handleSingleClick"
    @row-dblclick="handleDoubleClick"
  >
    <el-table-column
      :label="__('Report Name')"
      prop="report_instance_name"
      min-width="120"
    />
    <el-table-column
      :label="__('Description')"
      prop="report_instance_description"
      min-width="150"
    >
      <template slot-scope="scope">
        <el-tooltip
          :id="generateRef(scope.row)"
          :ref="generateRef(scope.row)"
          placement="top-end"
          class="report-instance-desc"
          :disabled="!isActiveEllipsis(scope.row)"
          ><div class="report-instance-desc-tooltip" slot="content">
            {{ scope.row.report_instance_description }}
          </div>
          <div>
            {{ scope.row.report_instance_description }}
          </div></el-tooltip
        >
      </template>
    </el-table-column>
    <el-table-column :label="__('Status')" prop="status">
      <template slot-scope="scope">
        <div style="display: flex; align-items: center">
          <span style="text-transform: capitalize">{{ scope.row.status }}</span>
          <div
            :style="getStatusStyles(scope.row.status)"
            :class="getStatusClass(scope.row.status)"
          ></div>
        </div>
      </template>
    </el-table-column>
    <el-table-column>
      <template v-if="isReportCompleted(scope.row)" slot-scope="scope">
        <div
          @click.stop.prevent="viewChart(scope.row)"
          style="display: flex; align-items: center"
        >
          <span class="view-report-btn"> {{ __("View Report") }} </span>
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
import { mapState } from "vuex";
import _ from "lodash";
import {
  STATUS_COMPLETED,
  STATUS_FAILED,
  STATUS_PROCESSING,
  STATUS_QUEUED
} from "@/views/analyse/reports/reportConstants";

export default {
  name: "ReportInstanceTable",
  props: {
    reportInstances: {
      type: Array,
      required: true
    },
    formAction: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      currentRowKey: null
    };
  },
  computed: {
    ...mapState("reports", {
      reportsLoading: state => state.loading
    }),
    isReportCompleted() {
      return report => report.status === STATUS_COMPLETED;
    },
    isReportInstanceIssuedBySystem() {
      return report => report.initiator === "system";
    },
    isActiveEllipsis() {
      return row => {
        let ref = this.generateRef(row);
        let el = document.getElementById(ref);
        if (el) {
          return el.offsetWidth < el.scrollWidth;
        }
        return false;
      };
    }
  },
  methods: {
    handleSingleClick(row, column, event) {
      if (event) {
        event.stopPropagation();
      }
      this.currentRowKey = row.report_instance_id;
      this.$emit("select", row);
    },

    reportInstanceGenerationCompleted(row) {
      return this.isReportCompleted(row);
    },

    generateRef(row) {
      return "report-instance-" + row.report_instance_id;
    },

    handleDoubleClick(row, column, event) {
      event.stopPropagation();
      // this.handleSingleClick(row, column, event);
      this.$nextTick(() => {
        if (
          this.reportInstanceGenerationCompleted(row) &&
          !this.isReportInstanceIssuedBySystem(row)
        ) {
          let index = _.findIndex(this.reportInstances, row);
          this.$emit("edit", index, row);
        }
      });
    },

    viewChart(row, column, event) {
      if (event) {
        event.stopPropagation();
      }
      this.handleSingleClick(row, column, event);
      this.$nextTick(() => {
        if (this.reportInstanceGenerationCompleted(row)) {
          let index = _.findIndex(this.reportInstances, row);
          this.$emit("show-chart", index, row);
        }
      });
    },

    getStatusClass(status) {
      return status === STATUS_PROCESSING ? "processing" : "";
    },

    getStatusStyles(status) {
      let statusStyles = {
        width: "16px",
        height: "16px",
        "border-radius": "8px",
        "margin-left": "10px"
      };

      const statusColorMap = {
        [STATUS_PROCESSING]: "#0583D6",
        [STATUS_COMPLETED]: "#1AD1A1",
        [STATUS_FAILED]: "#F84856",
        [STATUS_QUEUED]: "#F0C046"
      };

      statusStyles["background-color"] =
        statusColorMap[status] || "var(--theme-color)";
      return statusStyles;
    }
  },
  watch: {
    formAction(val) {
      if (!val) {
        this.currentRowKey = null;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@/styles/content-list.scss";

@keyframes fade-in-fade-out {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.processing {
  animation: fade-in-fade-out 1s infinite;
}

.view-report-btn {
  cursor: pointer;
  color: mediumblue;
  //font-size: 0.825rem;
}

.report-instance-desc {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

<style lang="scss">
.report-instance-desc-tooltip {
  width: 300px;
}
</style>
