<template>
  <div>
    <query-condition-builder
      :fields="columnsForReportQueryBuilder(selectedDatastoreStructure)"
      :value="eb"
      :fieldTypes="fieldTypes"
      :operators="operators"
      v-loading="loading"
      @previewDataStore="previewResponse"
    >
    </query-condition-builder>

    <div slot="footer" style="display: flex;justify-content: flex-end;">
      <el-button
        type="primary"
        @click="handleSaveQueryBuilder"
        class="submitBtn"
        >{{ __("Save") }}</el-button
      >
    </div>

    <el-dialog
      v-if="showModal"
      :visible.sync="showModal"
      :close-on-click-modal="false"
      :show-close="true"
      append-to-body
      destroy-on-close
      lock-scroll
      title="Series Data"
      width="90%"
      top="5vh"
    >
      <preview-table
        :tableData="dsData"
        :tableColumns="selectedDatastoreStructure"
        :is-system-log="isTaskLogDs(dataStoreId)"
        disable-navigation
        show-pagination
        :search-visible="false"
        :show-query-condition-builder-icon="false"
        :show-refresh-icon="false"
        :loading-table="loadingCollectionData"
        :sortable="false"
      >
        <el-form class="form-container" slot="paginationToolbar">
          <pagination-toolbar
            :content-api="getDataStoresCollection"
            :show-search="false"
            :show-slot="true"
            list-changed-event-name="documents-changed"
            @success="handleFetchSuccess"
          >
          </pagination-toolbar>
        </el-form>
      </preview-table>
    </el-dialog>
  </div>
</template>

<script>
import QueryConditionBuilder from "@/components/queryConditionBuilder/QueryConditionBuilder";

import PreviewTable from "@/components/queryConditionBuilder/PreviewTable";
import DataStore from "@/mixins/DataStore";
import PaginationToolbar from "@/components/PaginationToolbar";
import { EventBus } from "@/EventBus";
import ReportsUtil from "@/mixins/ReportsUtil";

export default {
  components: {
    PaginationToolbar,
    QueryConditionBuilder,
    PreviewTable
  },
  mixins: [DataStore, ReportsUtil],
  props: {
    dataStoreId: {
      required: true
    },
    taskId: {
      required: true
    },
    timeFrame: {
      required: true
    },
    selectedDatastoreStructure: {
      type: Array
    },
    queryCondition: {
      default: null
    },
    selectedQueryConditionId: null
  },

  data() {
    return {
      showModal: false,
      loading: false,
      requestingDataStoreData: false
    };
  },

  methods: {
    async previewResponse() {
      await this.buildRequestData(
        this.dataStoreId,
        this.eb.root.toJSON(),
        this.taskId,
        this.timeFrame
      );
      this.showModal = true;
      this.$nextTick(() => {
        EventBus.$emit("documents-changed", this.dsData, { page: 1 });
      });
    },

    handleSaveQueryBuilder() {
      this.$emit("save-query-condition", this.eb);
    }
  },

  watch: {
    selectedQueryConditionId(val) {
      if (val) {
        this.setNewQueryCondition(this.queryCondition);
      }
    }
  },

  created() {
    if (this.queryCondition) {
      this.setNewQueryCondition(this.queryCondition);
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/pagination-toolbar.scss";
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/button.scss";
@import "~@/styles/node_common.scss";
</style>
