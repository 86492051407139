<script>
import List from "./pages/list";
import CreateOrEdit from "./pages/createOrEdit";
import BaseIndex from "@/views/build/content/mixins/BaseIndex";
import { mapActions, mapState } from "vuex";
import moment from "moment-timezone";
import _ from "lodash";

export default {
  name: "ReportsIndex",
  mixins: [BaseIndex],
  components: {
    List,
    CreateOrEdit
  },

  computed: {
    ...mapState("app", {
      selectedAccountId: state => state.selectedAccountId
    })
  },

  methods: {
    ...mapActions("tasks", {
      getTasks: "getTasks"
    }),

    ...mapActions("dataStores", {
      getDataStores: "getDataStores"
    }),

    initContent(type = "report-template") {
      if (type === "report-template") {
        return {
          report_name: "",
          source_id: null,
          source_name: "datastore",
          chart_style: "Column",
          report_description: "",
          y_axis_label: "",
          y_axis_data_source_field: "_id",
          y_axis_data_source_function: "count",
          x_axis_label: "",
          x_axis_data_store: "_timestamp",
          timestamp_column_name: "_timestamp",
          data_fields: {
            selected_series_type: "form_field_value",
            series_data: [
              {
                series_name: "",
                series_query_condition: "",
                series_stack_group_name: "",
                series_color: ""
              }
            ]
          },
          snapshot_time_frame: "last_7_days",
          task_id: 0,
          start_date: moment().format("YYYY-MM-DD"),
          end_date: moment().format("YYYY-MM-DD")
        };
      } else {
        return {
          report_instance_id: null,
          report_instance_name: "",
          report_instance_description: null,
          date_range: [],
          task_id: null,
          send_notification: 0,
          initiator: "user",
          report: {
            id: null
          },
          requested_by: {}
        };
      }
    },

    initializeContents() {
      this.getTasks({ fetch_all: 1, ignore_task_type_filter: 1 });
      this.getDataStores();
    },
    replaceContent(content) {
      if (content) {
        const index = _.findIndex(this.contents, {
          [this.primaryKey]: content[this.primaryKey]
        });

        const contentUpdated = !_.isEqual(content, this.contents[index]);

        if (index !== -1) {
          const contentObj = contentUpdated ? this.contents[index] : content;
          this.setSelectedObject(index, contentObj);
        }
      }
    }
  },

  created() {
    this.contentType = "Report";
    this.primaryKey = "report_id";
    this.initializeContents();
    this.scope = "ac";
  },

  watch: {
    selectedAccountId: "initializeContents"
  }
};
</script>

<style lang="scss" scoped></style>
