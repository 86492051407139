<template>
  <highcharts
    v-loading="isLoading"
    class="chart"
    :options="chartOptions"
  ></highcharts>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

export default {
  props: {
    report: {
      type: Object,
      required: true
    },

    title: {
      type: String,
      default: ""
    },

    fetchData: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      updateArgs: [true, true, { duration: 1000 }],
      chartOptions: {
        chart: {
          type: this.report.chart_style.toLowerCase()
        },
        credits: {
          enabled: false
        },
        title: {
          text: this.title
        },
        subtitle: {
          text: ""
        },
        xAxis: {
          title: {
            text: this.report.x_axis_label
          },
          categories: []
        },
        yAxis: {
          title: {
            text: this.report.y_axis_label
          }
        },
        plotOptions: {},
        series: []
      },
      isLoading: false
    };
  },

  mounted() {
    if (this.fetchData) {
      this.loadChartData();
    }
  },

  methods: {
    ...mapActions("reports", {
      generateChartData: "generateChartData"
    }),

    /**
     * load chart data
     */
    loadChartData() {
      this.isLoading = true;
      let report = _.cloneDeep(this.report);
      report.date_format = this.displayDateFormat;
      this.generateChartData({ ...report })
        .then(data => {
          this.chartOptions.series = data.series;
          this.chartOptions.xAxis.categories = data.xAxis.categories;
          this.chartOptions.subtitle.text = data.subtitle.text;
          this.$set(this.chartOptions, "plotOptions", data.plotOptions || {});
          this.isLoading = false;
        })
        .catch(err => {
          this.$message({
            type: "error",
            message: err.message
          });
          this.isLoading = false;
        });
    }
  },

  computed: {
    ...mapGetters("app", {
      displayDateFormat: "displayDateFormat"
    })
  },

  watch: {
    report: {
      deep: true,
      handler: function() {
        this.$set(this.chartOptions.title, "text", this.title);
        this.$set(
          this.chartOptions.xAxis.title,
          "text",
          this.report.x_axis_label
        );
        this.$set(
          this.chartOptions.yAxis.title,
          "text",
          this.report.y_axis_label
        );
      }
    }
  }
};
</script>
